import { useApi } from "@api/api";
import { useNewMeal } from "@hooks/useNewMeal";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { HiRefresh } from "react-icons/hi";
import { CgChevronLeft } from "react-icons/cg";
import Lottie from "lottie-react";
import brain from "/src/lotties/brain.json";
import FeedbackModal from "@components/FeedbackModal";
import {tagEvent} from "@components/util";
import OptionsRobot from "@assets/options-robot.svg";
const getRandomItem = (list) => {
  return list[Math.floor(Math.random() * list.length)];
};
const mealPrompts = [
  "What's Cooking, Good Looking?",
  "Dinner Quest: Choose Your Adventure",
  "Feast Mode: Activate!",
  "Let's Get Culinary, Comrade!",
  "Chef's Hat On: Let's Decide!",
  "Savor the Flavor: Your Pick!",
  "What's on the Menu, Mate?",
  "Culinary Countdown: Choose Now!",
  "Plate it Up: Your Choice!",
  "Let's Create a Feast!",
  "Whip Up Some Magic!",
  "Mystery Meal: Unveil the Deliciousness",
  "Flavor Fiesta: Let's Party!",
  "Cuisine Conundrum: Solve It!",
  "Palate Pleasers: Select Your Star Dish",
  "Hungry for Adventure? Choose Your Quest",
  "Taste Bud Travel: Where to Today?",
];

const SelectMealView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    mealState,
    selectMeal,
    mealOptions,
    setMealOptions,
    clearMealOptions,
  } = useNewMeal();
  const { useMealOptionsQuery, giveFeedback } = useApi();
  const navigate = useNavigate();

  const onBack = () => navigate("/meal/new");

  const {
    data: newMealOptions,
    isLoading,
    isError,
    error,
  } = useMealOptionsQuery(mealState, mealOptions.options.length === 0);

  useEffect(() => {
    if (mealState.ingredients_list.length === 0) {
      navigate("/meal/new");
    }
  }, [mealState.ingredients_list, navigate]);

  useEffect(() => {
    if (newMealOptions?.error) {
      toast.error(newMealOptions.msg);
      if (newMealOptions.status === 402) navigate("/trialEnded");
      if (newMealOptions.status === 429) navigate("/slowDown");
    } else if (newMealOptions) {
      setMealOptions(newMealOptions);
    }
  }, [newMealOptions, mealOptions, setMealOptions]);

  function handleSelectMeal(event) {
    selectMeal(event.target.innerText);
    navigate("/meal/recipe");
  }

  if (isLoading) return <LoadingAnimation />;
  if (error) {
    const msgDetail = JSON.parse(error.message).detail;
    toast.error("Error loading meal options: " + msgDetail, {
      id: "error-loading-meal-options",
    });
  }
  if (isError) return <div>Error... {JSON.stringify(error)}</div>;

  return (
    <div className="p-4">
      {isModalOpen && (
        <FeedbackModal
          aii_id={mealOptions.aii_id}
          onSubmit={(value) => {
            setIsModalOpen(false);
            giveFeedback.mutate(value);
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      <div className="divider pb-4">{getRandomItem(mealPrompts)}</div>
      <div className="flex flex-col space-y-4 pb-4 justify-center">
        {mealOptions.options.map((meal) => (
          <button
            key={meal}
            className="btn btn-primary"
            onClick={handleSelectMeal}
          >
            {meal}
          </button>
        ))}
      </div>
      <div className="divider">
        <button
          className="btn btn-sm text-xs btn-ghost font-light"
          onClick={() => setIsModalOpen(true)}
        >
          Something off? Give Feedback
        </button>
      </div>

      <div className="flex flex-row justify-between items-center">
        <button className="btn btn-neutral" onClick={() => {
          tagEvent("select_meal_go_back", { button_name: "Go Back" });
          navigate("/meal/new");
        }}>
          <CgChevronLeft />
          Go Back
        </button>
        <button className="btn btn-secondary" onClick={() => {
          tagEvent("select_meal_reload", { button_name: "Reload" });
          clearMealOptions();
        }}>
          <HiRefresh />
          Reload
        </button>
      </div>
      <div className="flex justify-center">
        <img src={OptionsRobot} alt="options robot" className="w-64" />
      </div>
    </div>
  );
};

export default SelectMealView;

const funnyLoadingText = [
  "Mixing digital ingredients...",
  "Cooking up a digital delight...",
  "Simmering code to perfection...",
  "Whisking through the binary broth...",
  "Baking bits and bytes...",
  "Marinating data in secret sauce...",
  "Grilling glitches for a flawless feast...",
  "Sautéing pixels to pixel-perfection...",
  "Seasoning algorithms with a pinch of creativity...",
  "Chopping bugs for a smoother experience...",
  "Blending graphics for a tasty interface...",
  "Fermenting ideas into digital delicacies...",
];

const getRandomText = () => {
  const randomIndex = Math.floor(Math.random() * funnyLoadingText.length);
  return funnyLoadingText[randomIndex];
};

const LoadingAnimation = () => {
  const [text, setText] = useState(getRandomText());
  const [isVisible, setIsVisible] = useState(true);
  const isVisibleRef = useRef(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isVisibleRef.current) {
        setText(getRandomText());
        setIsVisible(true);
        isVisibleRef.current = true;
      } else {
        setIsVisible(false);
        isVisibleRef.current = false;
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="w-64 h-64 mt-32">
        <Lottie animationData={brain} />
      </div>
      <p className={`${isVisible ? "fade-in-text" : "fade-out-text"} text-xl`}>
        {text}
      </p>
    </div>
  );
};
