import React from "react";
import Sidebar from "@components/Navigation/Drawer.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { LuScroll } from "react-icons/lu";
import { GiKnifeFork } from "react-icons/gi";
import { tagEvent } from "@components/util";
import { RxHamburgerMenu } from "react-icons/rx";

const Navbar: React.FC = () => {
  return (
    <>
      <Sidebar />
      <MobileNavbar />
    </>
  );
};

export const MobileNavbar = () => {
  const NavLinks = [
    {
      pathName: "/meal/new",
      icon: <GiKnifeFork />,
      label: "Kitchen",
    },
    {
      pathName: "/recipe-saved",
      icon: <LuScroll />,
      label: "Cookbook",
    },
    {
      pathName: "/more",
      icon: <RxHamburgerMenu />,
      label: "More",
    },
  ];

  return (
    <div className="btm-nav bg-base-200">
      {NavLinks.map((link) => (
        <NavBarButton
          key={link.pathName}
          path={link.pathName}
          icon={link.icon}
          label={link.label}
        />
      ))}
    </div>
  );
};

export default Navbar;

const NavBarButton: React.FC<{
  path: string;
  icon: React.ReactNode;
  label: string;
}> = ({ path, icon, label }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = pathname == path;
  return (
    <div
      className={`flex text-2xl ${isActive ? "active" : ""}`}
      onClick={() => {
        tagEvent("menu_nav", { page_path: path });
        navigate(path);
      }}
    >
      <span className="text-xs font-light">{label}</span>
      {icon}
    </div>
  );
};
