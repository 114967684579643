import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import landingImage from "@assets/landin_robot_2.png";
import { Button } from "react-daisyui";
import { TbChevronCompactDown } from "react-icons/tb";
import RocketSalad from "@assets/rocket-salad.svg";
import CrystalBall from "@assets/crystal-ball.svg";
import ChefHat from "@assets/chef-hat.svg";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LandingView: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCodeFromUrl = queryParams.get("ref");

  useEffect(() => {
    if (referralCodeFromUrl) localStorage.setItem("ref", referralCodeFromUrl);
  }, [referralCodeFromUrl]);

  return <MobileLanding />;
};

export const MobileLanding: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content">
        <div className="max-w-md">
          <div className="flex justify-between w-full items-center max-w-md p-4">
            <span className="text-2xl font-bold">MealBot</span>
            <div className="flex items-center">
              <span className="text-sm font-light mr-2">Have an account?</span>

              <Button
                size="sm"
                color="primary"
                onClick={() => navigate("/login")}
              >
                Get Cookin!
              </Button>
            </div>
          </div>

          <div className="flex justify-center items-center max-w-md">
            <figure className="pointer-events-none object-fit">
              <img src={landingImage} alt="Login" className="rounded-xl" />
            </figure>
          </div>

          <div className="flex flex-col items-center mt-2 mb-8">
            <div className="font-bold text-center text-5xl w-full">MealBot</div>
            <div className="font-light text-center">
              Your AI-powered cooking helper
            </div>
            <div className="font-medium text-2xl mt-8 mb-1">
              What will we make today?
            </div>
            <GetStartedButton />
          </div>
          <div className="w-full text-xl">
            Ingredients to recipes in seconds.
          </div>
          <div className="w-full text-xl">
            AI powered just bring your appetite.
          </div>
          <div className="flex justify-center w-full items-center">
            <TbChevronCompactDown size={96} />
          </div>
          <div className="text-5xl font-bold text-center w-full mt-16 mb-4">
            Features
          </div>
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">
                Speedy Gourmet
                <figure className="ml-8">
                  <img src={RocketSalad} alt="Rocket Salad" />
                </figure>
              </h2>
              <p>
                Toss us your ingredients, and we’ll transform them into
                scrumptious meal ideas in under a minute!
              </p>
            </div>
          </div>

          <div className="card bg-base-100 shadow-xl mt-4">
            <div className="card-body">
              <h2 className="card-title">
                <figure className="mr-8">
                  <img
                    src={CrystalBall}
                    alt="Crystal Ball"
                    className="max-h-24"
                  />
                </figure>
                Culinary Clairvoyance
              </h2>
              <p>
                Our AI isn’t just smart – it’s a gastronomic genius, tailoring
                recipes to match your pantry's personality.
              </p>
            </div>
          </div>

          <div className="card bg-base-100 shadow-xl mt-4">
            <div className="card-body">
              <h2 className="card-title">
                Step-by-Step Sidekick
                <figure className="ml-8">
                  <img src={ChefHat} alt="Chef Hat" className="max-h-24" />
                </figure>
              </h2>
              <p>
                Selected a recipe? Let's cook together with easy-to-follow
                instructions.
              </p>
            </div>
          </div>

          <div className="mt-8">
            <button
              className="btn btn-primary btn-block"
              onClick={() => navigate("/login")}
            >
              Start your cooking adventure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GetStartedButton: React.FC = () => {
  const navigate = useNavigate();

  const navigateTo = (route: string) => {
    navigate(route);
  };

  return (
    <button
      className="btn btn-primary text-white btn-block min-h-16"
      type="button"
      onClick={() => navigateTo("/login")}
    >
      <span className="text-xl font-bold">Make an Account</span>
      <MdOutlineKeyboardArrowRight />
    </button>
  );
};

export default LandingView;
